// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'
import ProAndCon from 'components/Contentful/ProAndCon'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Funktion eines Wärmepumpen-Boilers"
        meta={[
          { name: 'description', content: 'Was ein Wärmepumpen-Boiler ist und wie er funktioniert, wird Ihnen von unseren Spezialisten anschaulich und umfassend erklärt.' },
          { name: 'keywords', content: 'Wärmepumpen-Boiler, Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='2uJgzBW2DzDr6nKCcgtH8B-5xFiTqW615VTD6pch7O6wQ'
          id='waermepumpen-boiler'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5xFiTqW615VTD6pch7O6wQ",
    "updatedAt": "2024-03-04T15:00:34.700Z",
    "title": "Wärmepumpen-Boiler",
    "anchorName": "waermepumpen-boiler",
    "text": "<ul>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/waermepumpen-boiler/#was-ist-ein-waermepumpen-boiler-und-wie-funktioniert-er\">Was ist ein Wärmepumpen-Boiler und wie funktioniert er?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/waermepumpen-boiler/#wo-kommt-ein-waermepumpen-boiler-zum-einsatz\">Wo kommt ein Wärmepumpen-Boiler zum Einsatz?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/waermepumpen-boiler/#waermepumpen-boiler-oder-gleich-eine-waermepumpe\">Wärmepumpen-Boiler oder gleich eine Wärmepumpe?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/waermepumpen-boiler/#vor-und-nachteile-eines-waermepumpen-boilers\">Vor- und Nachteile eines Wärmepumpen-Boilers</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/waermepumpen-boiler/#hinweis-wann-wird-ein-waermepumpen-boiler-fuer-sie-interessant\">Hinweis: Wann wird ein Wärmepumpen-Boiler für Sie interessant?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/waermepumpen-boiler/#wuenschen-sie-eine-beratung\">Wünschen Sie eine Beratung?</a></li>\n</ul>\n",
    "image": {
        "description": "Wärmepumpen-Boiler von NIBE",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/Tgv3QK7EvbmaNaBqVQ6f8/36a057f83525cb0aa11da2194202a9fc/WP-Bild-von-NIBE-E_B.png"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='2uJgzBW2DzDr6nKCcgtH8B-2jMUbYplFBUcdBOOVgYDdZ'
          id='was-ist-ein-waermepumpen-boiler-und-wie-funktioniert-er'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "2jMUbYplFBUcdBOOVgYDdZ",
    "updatedAt": "2021-09-09T08:08:33.114Z",
    "title": "Was ist ein Wärmepumpen-Boiler und wie funktioniert er?",
    "anchorName": "was-ist-ein-waermepumpen-boiler-und-wie-funktioniert-er",
    "text": "<p>Ein Wärmepumpen-Boiler - auch bekannt als Brauchwasser-Wärmepumpe - <strong>dient zur ökonomischen und effizienten Warmwasserversorgung von Haushalten sowie Gewerben</strong>.</p>\n<p>Wie es der Name schon verrät, funktioniert ein Wärmepumpen-Boiler wie eine <a href=\"https://www.heizungsmacher.ch/wissen/wie-funktioniert-eine-waermepumpe/\">Heizungs-Wärmepumpe</a>. Diese saugt mittels eines Ventilators Umgebungsluft an. Am Wärmetauscher verdampft dann das Kältemittel, welches ein wichtiges Hilfsmittel für die Temperaturübertragung ist. Durch den Kompressionsvorgang im Verdichter steigt die Temperatur des Kältemittels bis das definierte Temperaturniveau erreicht wird. Ein zweiter Wärmetauscher ermöglicht schliesslich die Temperaturübertragung auf das Wasser, welches sich im Speicher befindet. <strong>Das Beste am Wärmepumpen-Boiler ist, dass 65% der benötigten Energie aus der Umgebungsluft gewonnen wird. Die restlichen 35% kommen vom Stromnetz für den Kompressionsvorgang</strong>.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='2uJgzBW2DzDr6nKCcgtH8B-wAJfaeZxJFPS0BSjCF18p'
          id='wo-kommt-ein-waermepumpen-boiler-zum-einsatz'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "wAJfaeZxJFPS0BSjCF18p",
    "updatedAt": "2021-09-09T08:08:00.987Z",
    "title": "Wo kommt ein Wärmepumpen-Boiler zum Einsatz?",
    "anchorName": "wo-kommt-ein-waermepumpen-boiler-zum-einsatz",
    "text": "<p><strong>Ein Wärmepumpen-Boiler ist die stromsparende Alternative zum Elektro-Boiler</strong>. Er wird immer mehr für die effiziente und ökonomische Warmwasserversorgung von Privathaushalten und Gewerbebetrieben eingesetzt. In der Regel wird er im Keller installiert und benötigt und hat lediglich einen Platzbedarf von ungefähr 1 m². Das entstehende Kondensat wird direkt in einen Abfluss geleitet. Um eine zuverlässige Funktion der Anlage zu gewährleisten, sollte die Raumgrösse, in dem sich der Wärmepumpen-Boiler befindet, mindestens 20 m² betragen.</p>\n<p>Die ideale Umgebungstemperaturen liegen zwischen 8 und 30 ℃. <strong>Bei Temperaturen unter 8 ℃ benötigt der Boiler eine zusätzliche Abtaueinrichtung</strong>. Ein gut isolierter Keller wird eine Temperatur von ungefähr 15 ℃ selten unterschreiten.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='2uJgzBW2DzDr6nKCcgtH8B-7mmvnr3s7crLLqNDYGIEz6'
          id='waermepumpen-boiler-oder-gleich-eine-waermepumpe'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "7mmvnr3s7crLLqNDYGIEz6",
    "updatedAt": "2021-09-09T10:26:17.298Z",
    "title": "Wärmepumpen-Boiler oder gleich eine Wärmepumpe?",
    "anchorName": "waermepumpen-boiler-oder-gleich-eine-waermepumpe",
    "text": "<p><strong>In der Praxis wird der Austausch von einem Elektro-Boiler oft mit dem <a href=\"https://www.heizungsmacher.ch/heizungsersatz/\">Heizungsersatz</a> kombiniert</strong>. Kommt dabei eine hocheffiziente Wärmepumpe <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">(Luft/Wasser oder Sole/Wasser)</a> zum Einsatz, wird statt eines separaten Wärmepumpen-Boilers das Brauchwarmwasser direkt mit der Heizungswärmepumpe produziert.</p>\n<p>Fragen Sie sich warum? Luft/Wasser Heizungswärmepumpen verwenden immer Aussenluft und kühlen daher den Keller nicht ab. Bei <a href=\"https://www.heizungsmacher.ch/wissen/wie-funktioniert-eine-erdsondenbohrung/\">Sole/Wasser Heizungswärmepumpen</a> steht mit der Erdsondenbohrung eine konstante und sehr effiziente Primärenergie zur Verfügung. Dadurch macht es in solchen Situationen oft keinen Sinn einen separaten Wärmepumpen-Boiler zu installieren. Die <a href=\"https://www.heizungsmacher.ch/wissen/wie-hoch-sind-die-kosten-einer-waermepumpe/#die-kosten-einer-waermepumpe-auf-einen-blick\">Kosten für Installation und Betrieb</a> sind daher meist tiefer. Wir Heizungsmacher haben uns auf den kompletten Heizungsersatz inklusive Warmwasser spezialisiert. Daher installieren wir nur sehr selten einzelne Wärmepumpen-Boiler.</p>\n<p><strong>Falls Sie noch eine alte <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/\">Öl-</a> oder Elektroheizung besitzen und bald ein Heizungsersatz ansteht, dann empfehlen wir in den meisten Fällen eine kombinierte Lösung</strong>. Lassen Sie sich von <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">unseren Experten</a> beraten um eine optimierte und kombinierte Lösung zu finden.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='2uJgzBW2DzDr6nKCcgtH8B-p7BXAmGnGtl82wCoPvCnn'
          id='vor-und-nachteile-eines-waermepumpen-boilers'
        >
          <ProAndCon
            {...{
    "type": "proAndCon",
    "id": "p7BXAmGnGtl82wCoPvCnn",
    "updatedAt": "2021-09-09T11:03:37.787Z",
    "title": "Vor- und Nachteile eines Wärmepumpen-Boilers",
    "anchorName": "vor-und-nachteile-eines-waermepumpen-boilers",
    "pros": [
        "Sehr effizientes Gesamtsystem",
        "Tiefe Betriebskosten",
        " Benutzerfreundlich und betriebssicher",
        "Ideal zur Kombination mit einer Photovoltaikanlage",
        "Umweltfreundlich"
    ],
    "cons": [
        "Umgebungsluft wird oft durch eine Ölheizung erwärmt, damit sind viele Effizienzvorteile und die Umweltfreundlichkeit weg",
        "Entzieht dem Keller die Wärme, Resultat: kalte Räume",
        "Ist auf die Umgebungstemperaturen angewiesen",
        "Unter 8 ℃ wird eine Abtaueinrichtung benötigt"
    ],
    "text": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='2uJgzBW2DzDr6nKCcgtH8B-c5KzirtXog3oHGnEHFAHr'
          id='hinweis-wann-wird-ein-waermepumpen-boiler-fuer-sie-interessant'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "c5KzirtXog3oHGnEHFAHr",
    "updatedAt": "2021-09-09T10:14:15.734Z",
    "title": "Hinweis: Wann wird ein Wärmepumpen-Boiler für Sie interessant?",
    "anchorName": "hinweis-wann-wird-ein-waermepumpen-boiler-fuer-sie-interessant",
    "text": "<p><a href=\"https://www.heizungsmacher.ch/kontakt/\"><img src=\"//images.ctfassets.net/wbm1kpreb3m8/Z6JqXqZjoE9yGu8j3Hu6K/ce1a412328b4c36c377b62eb525a5309/CTA-Button-Kontakt.png\" alt=\"CTA Button Kontakt\"></a></p>\n<p>Der Wärmepumpen-Boiler wird für Sie interessant, <strong>wenn Sie Ihren alten Elektro-Boiler ersetzen wollen</strong>. In der Praxis wird jedoch <strong>der Austausch eines Elektro-Boilers oft mit einem <a href=\"https://www.heizungsmacher.ch/heizungsersatz/\">Heizungsersatz</a> kombiniert</strong>, bei dem das Heizen und die Warmwassererzeugung von einer einzigen Anlage - einer Wärmepumpe - produziert wird.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='2uJgzBW2DzDr6nKCcgtH8B-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
